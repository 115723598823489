<template>
    <div class="editor__menu">
        <template v-for="(btn, index) in list" :key="index">
            <div v-if="btn.type == 'dvider'" class="dvider"></div>
            <div v-else-if="btn.type == 'fileupload'" class="fileupload">
                <input ref="fileUpload" type="file" @change="uploadFile" hidden>
                <editor-btn :icon="btn.icon" :action="btn.action" :isActive="btn.isActive"/>
                <!-- <button @click="$emit('upload', 1)">
                    Increase by 1
                </button> -->
            </div>
            <editor-btn v-else :icon="btn.icon" :action="btn.action" :isActive="btn.isActive"/>
        </template>
    </div>
</template>
<script setup>
import { EditorContent } from '@tiptap/vue-3'
import EditorBtn from './EditorBtn.vue';
import { defineProps, reactive, defineEmits } from 'vue';
import { ref } from 'vue'
import ApiService from '@/core/services/ApiService';

const props = defineProps({ 
    editor:{
        type: Object,
        required: true,
    }
})

const emit = defineEmits(['upload'])

const fileUpload = ref('')
const file = ref('')

const chooseFiles = ()=>{
    fileUpload.value[0].click()
}

const uploadFile = (event) => {
  file.value = event.target.files[0];
  submitFile()
};


const submitFile = async () => {
    const forma = new FormData();
    forma.append('file', file.value)


//   const reader = new FileReader();
//   reader.readAsDataURL(file.value);
//   reader.onload = async () => {
//     const encodedFile = reader.result.split(",")[1];
//     const data = {
//       file: encodedFile,
//       fileName: file.value.name,
//     //   fileExtension: fileExtension.value,
//       fileMimeType: file.value.type,
//     };
    try {
      const endpoint = "news/img";
      const response = await ApiService.post(endpoint, forma);
      const imgUrl = process.env.VUE_APP_API_URL+'/' + response.data;
      if (imgUrl) {
        props.editor.chain().focus().setImage({ src: imgUrl }).run()
      }
    } catch (error) {
      console.error(error);
    }
//   };
}

const list = [
    {
        icon: 'bi bi-arrow-counterclockwise',
        action: ()=> props.editor.chain().focus().undo().run(),
        isActive: ()=> props.editor.isActive('undo'),
    },
    {
        icon: 'bi bi-arrow-clockwise',
        action: ()=> props.editor.chain().focus().redo().run(),
        isActive: ()=>props.editor.isActive('redo'),
    },
    {
        type: 'dvider'
    },
    {
        icon: 'bi bi-type-h1',
        action: ()=>props.editor.chain().focus().toggleHeading({ level: 1 }).run(),
        isActive: ()=>props.editor.isActive('heading', { level: 1 }),
    },
    {
        icon: 'bi bi-type-h2',
        action: ()=>props.editor.chain().focus().toggleHeading({ level: 2 }).run(),
        isActive: ()=>props.editor.isActive('heading', { level: 2 }),
    },
    {
        icon: 'bi bi-type-h3',
        action: ()=>props.editor.chain().focus().toggleHeading({ level: 3 }).run(),
        isActive: ()=>props.editor.isActive('heading', { level: 3 }),
    },
    {
        icon: 'bi bi-type-bold',
        action: ()=>props.editor.chain().focus().toggleBold().run(),
        isActive: ()=>props.editor.isActive('bold'),
    },
    {
        icon: 'bi bi-type-italic',
        action: ()=>props.editor.chain().focus().toggleItalic().run(),
        isActive: ()=>props.editor.isActive('italic'),
    },
    {
        icon: 'bi bi-type-underline',
        action: ()=>props.editor.chain().focus().toggleUnderline().run(),
        isActive: ()=>props.editor.isActive('underline'),
    },



    {
        icon: 'bi bi-text-left',
        action: ()=>props.editor.chain().focus().setTextAlign('left').run(),
        isActive: ()=>props.editor.isActive({ textAlign: 'left' }),
    },
    {
        icon: 'bi bi-text-center',
        action: ()=>props.editor.chain().focus().setTextAlign('center').run(),
        isActive: ()=>props.editor.isActive({ textAlign: 'center' }),
    },
    {
        icon: 'bi bi-text-right',
        action: ()=>props.editor.chain().focus().setTextAlign('right').run(),
        isActive: ()=>props.editor.isActive({ textAlign: 'right' }),
    },
    // {
    //     icon: 'stricethrough',
    //     title: 'Strice',
    //     action: ()=>props.editor.chain().focus().toggleStrice().run(),
    //     isActive: ()=>props.editor.isActive('strice'),
    // },
    
    // {
    //     icon: 'mark-pen-line',
    //     title: 'Highlight',
    //     action: ()=>props.editor.chain().focus().toggleHighlight().run(),
    //     isActive: ()=>props.editor.isActive('highlight'),
    // },
    {
        type: 'dvider'
    },
    {
        icon: 'bi bi-code-slash',
        action: ()=>props.editor.chain().focus().toggleCode().run(),
        isActive: ()=>props.editor.isActive('code'),
    },
    {
        icon: 'bi bi-list-ol',
        action: ()=>props.editor.chain().focus().toggleBulletList().run(),
        isActive: ()=>props.editor.isActive('bulletList'),
    },
    {
        icon: 'bi bi-list-task',
        action: ()=>props.editor.chain().focus().toggleOrderedList().run(),
        isActive: ()=>props.editor.isActive('orderedList'),
    },
    {
        icon: 'bi bi-image-fill',
        type: 'fileupload',
        action: ()=> chooseFiles(),
    },
]



</script>

<style lang="scss" scoped>
.editor{
    &__content{
        width: 100%;
    }
    &__menu{
        width: 100%;
        background: #fff;
        display: flex;
        gap: 10px;
        align-items: center;
        margin-bottom: 20px;
        padding: 10px;
        border: 1px solid rgb(219, 220, 224);
        border-radius: 5px;
        & .dvider{
            position: relative;
            &::after{
                display: block;
                height: 30px;
                background: rgb(217, 218, 221);
                width: 1px;
                content: '';
            }
        }
    }
}
</style>